<template>
    <div class="login-body">
        <div class="login-image"></div>
        <div class="card login-panel p-fluid">
            <div class="login-panel-content">
                <div class="p-grid">
                    <div class="p-col-3" style="text-align:left">
                        <img src="/assets/layout/images/login/icon-login.svg" alt="avalon-vue"/>
                    </div>
                    <div class="p-col-9" style="text-align:left">
                        <h2 class="welcome-text">Welcome!</h2>
                        <span class="guest-sign-in">Sign in to {{ cabinetName() }}</span>
                    </div>
                    <div class="p-col-12" style="text-align:left">
                        <label class="login-label">E-mail</label>
                        <div class="login-input">
                            <InputText
                                :class="{ 'p-invalid': v.username.required }"
                                v-model="username"
                                placeholder="E-mail"/>
                        </div>
                        <div class="error" v-if="v.username.required">Field is required</div>
                    </div>
                    <div class="p-col-12" style="text-align:left">
                        <label class="login-label">Password</label>
                        <div class="login-input">
                            <InputText
                                :class="{ 'p-invalid': v.username.required }"
                                v-model="password"
                                type="password" placeholder="Password"/>
                        </div>

                        <div class="error" v-if="v.password.required">Field is required</div>
                        <div class="error" v-if="v.username.unauthorized">Invalid e-mail or password</div>
                    </div>
                    <div class="p-col-12 p-md-6 button-pane">
                        <Button label="Sign In" @click="signin"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import UserService from '../service/UserService';

import cookies from 'vue-cookies';

export default {
    data() {
        return {
            username: '',
            password: '',
            v: {
                username: {
                    required: false,
                    unauthorized: false
                },
                password: {
                    required: false
                }
            }
        }
    },

    userService: null,
    created() {
        this.userService = new UserService();
    },
    methods: {
        goDashboard() {
            window.location = "/"
        },
        signin() {
            console.log(this.username);
            console.log(this.password);

            if (this.username === '') {
                this.v.username.required = true;
                this.v.username.unauthorized = false;
                return;
            } else {
                this.v.username.required = false;
                this.v.username.unauthorized = false;
            }

            if (this.password === '') {
                this.v.password.required = true;
                this.v.username.unauthorized = false;
                return;
            } else {
                this.v.password.required = false;
                this.v.username.unauthorized = false;
            }

            this.userService.authorize(this.username, this.password)
                .then(response => {
                    if (response.status && response.status === 200) {
                        let isAdmin = response.data.model.roleNames.includes('superadmin');

                        if(window.application == 'backoffice' && isAdmin == false){
                            this.username = '';
                            this.password = '';

                            return;
                        }

                        localStorage.setItem('user', JSON.stringify(response.data.model));

                        cookies.set('jwt', JSON.stringify(response.data.model))

                        window.location.replace('/sport-programs');
                    }

                    if (response.response.status === 401) {
                        this.v.username.unauthorized = true;
                        return;
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.$toast.add({severity: 'error', summary: 'An error occurred. Please try to perform the operation again.', life: 3000});
                    //throw error;
                });
        },
        cabinetName() {
            if (window.application === 'cabinet') {
                return 'Cabinet';
            } else if (window.application === 'backoffice') {
                return 'Admin';
            } else {
                throw 'Invalid application';
            }
        }
    }
}
</script>

<style scoped>

</style>
