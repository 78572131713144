<template>
    <div class="login-body">
        <Toast/>

        <div class="login-image"></div>
        <div class="card login-panel p-fluid">
            <div class="login-panel-content">
                <div class="p-grid">
                    <div class="p-col-12" style="text-align:left">
                        <h2 class="welcome-text">Account Activation</h2>
                    </div>
                    <div class="p-col-12" style="text-align:left">
                        <label class="login-label">E-mail</label>
                        <div class="login-input">
                            <InputText
                                convert indents v-model="email"
                                placeholder="E-mail"/>
                        </div>
                    </div>
                    <div class="p-col-12" style="text-align:left">
                        <label class="login-label">Password</label>
                        <div class="login-input">
                            <InputText
                                v-model="password"
                                type="password" placeholder="Password"/>
                        </div>
                    </div>
                    <div class="p-col-12" style="text-align:left">
                        <label class="login-label">Retype password</label>
                        <div class="login-input">
                            <InputText
                                v-model="retypePassword"
                                type="password" placeholder="Retype password"/>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-6 button-pane">
                        <Button label="Activate" @click="activate"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AccountsService from "@/service/AccountsService";

export default {
    data() {
        return {
            email: null,
            password: null,
            retypePassword: null,
        }
    },

    accountsService: null,
    created() {
        this.accountsService = new AccountsService();
    },
    mounted() {
        let that = this;
        this.email = that.$route.query.email;
    },
    methods: {
        activate() {
            if(this.password !== this.retypePassword){
                return;
            }

            let activateModel = {
                login: this.email,
                password: this.password,
                token: this.$route.query.token
            };

            this.accountsService.activate(activateModel)
                .then(() => {
                    window.location.replace('/login');
                })
                .catch(error => this.handleErrorResponse(error));
        }
    }
}
</script>

<style scoped>

</style>
