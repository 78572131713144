<template>
    <Login v-if="checkPath('/login') || !checkAuthorization()" />
    <ActivateAccount v-else-if="checkPath('/activate')" />
    <App v-else />
</template>

<script>
import App from './App';
import Login from './pages/Login';
import ActivateAccount from './pages/ActivateAccount';
import Cookies from "vue-cookies";

export default {
    methods: {
        checkPath(path){
            const currentURL = window.location.pathname;

            return path.toLowerCase() === currentURL.toLowerCase();
        },
        checkAuthorization(){
            let cookie = Cookies.get('jwt');

            if(cookie === null){
                return false;
            }

            return true;
        }
    },
    components: {
        "App": App,
        "Login": Login,
        "ActivateAccount": ActivateAccount,
    }
}
</script>

