import axios from 'axios'

const isProd = process.env.NODE_ENV === 'production'

const baseURL = isProd
    ? process.env.VUE_APP_API_URL_PROD
    : process.env.VUE_APP_API_URL

export default class UserService {
    authorize(email, password) {
        return axios.post(
            baseURL + 'authorization',
            {
                email: email,
                password: password
            })
            .then(response => response)
            .catch(error => error);
    }
}
