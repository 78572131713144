<template>
    <div class="layout-footer" style="height: 60px;">
        <span class="footer-text-left">
        </span>
    </div>
</template>

<script>
export default {
    name: "AppFooter"
}
</script>

<style scoped>

</style>
