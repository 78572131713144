import moment from 'moment'
import * as DateFormats from '@/helpers/constants'
import {prepareDate} from './functions'

const getDateOfCertainFormat = (date, format = 'formatUTC') => {
    if (typeof date === 'string' && date.indexOf('.') === 2)
        date = prepareDate(date, '.')
    const momentDate = moment(new Date(date))
    if (!momentDate.isValid() || date.length < 6)
        return date
    return momentDate.format(DateFormats[format])
}

const getNow = (format = 'formatUTC') => moment().format(DateFormats[format])

const getFuturePeriod = (format, countOfPeriod, nameOfPeriod = 'day') => {
    const momentDate = moment().add(countOfPeriod, nameOfPeriod);
    return momentDate.format(format);
}

const unixTimeStampToDate = (date) => moment.unix(date)
const isAfterDay = current => nextDay => moment(nextDay).isSameOrAfter(current)
const isValidDate = date => moment(date).isValid()
const getYear = () => moment().year()
const checkYear = (date, year = 1980) => moment(date).year() >= year

const getPeriod = (start, end) => (
    getDateOfCertainFormat(start, 'formatDefault') +
    ' - ' +
    getDateOfCertainFormat(end, 'formatDefault')
)

export {
    getDateOfCertainFormat,
    getNow,
    getPeriod,
    getFuturePeriod,
    unixTimeStampToDate,
    isAfterDay,
    isValidDate,
    getYear,
    checkYear
};
