<template>
    <div class="topbar clearfix">
        <Dialog :header="$t('header.settings')" :visible.sync="displayBasic">
            <div style="height: 200px; width: 300px;">
                <div class="p-field p-grid">
                    <label for="language" class="p-col-fixed" style="width:120px;">{{ $t('settings.language') }}</label>
                    <div class="p-col">
                        <Dropdown v-model="$i18n.locale" @change="onChangeLanguage" id="language" :options="languages"
                                  optionLabel="name" optionValue="code"/>
                    </div>
                </div>
            </div>
        </Dialog>

        <div class="topbar-left">
            <span class="app-topbar__logo">{{ cabinetName }}</span>
        </div>

        <div class="topbar-right">
            <button class="p-link" id="menu-button" @click="onMenuButtonClick">
                <i class="pi pi-angle-left"></i>
            </button>

            <button class="p-link" id="topbar-menu-button" @click="onTopbarMenuButtonClick">
                <i class="pi pi-bars"></i>
            </button>
            <ul :class="topbarItemsClass">
                <li v-if="profileMode === 'top' || horizontal"
                    :class="['profile-item', {'active-top-menu': activeTopbarItem === 'profile'}]"
                    @click="$emit('topbar-item-click',{originalEvent:$event,item:'profile'})">
                    <button class="p-link" @click="logout()">
                        <i class="pi pi-fw pi-sign-out"></i>
                    </button>

                </li>
            </ul>


        </div>
    </div>
</template>

<script>
import Cookies from "vue-cookies";

export default {
    data() {
        return {
            displayBasic: false,
            languages: [
                {name: 'En', code: 'en'},
                {name: 'Ru', code: 'ru'}
            ],
        }
    },
    props: {
        topbarMenuActive: Boolean,
        AppearanceprofileMode: String,
        profileMode: String,
        horizontal: Boolean,
        activeTopbarItem: String
    },
    methods: {
        onMenuButtonClick(event) {
            this.$emit('menubutton-click', event);
        },
        onTopbarMenuButtonClick(event) {
            this.$emit('topbar-menubutton-click', event);
        },
        onChangeLanguage() {
            return Cookies.set('locale', this.$i18n.locale);
        },
        logout() {
            window.localStorage.removeItem('user');
            Cookies.set('jwt', null);

            window.location.replace('/login');
        }
    },
    computed: {
        topbarItemsClass() {
            return ['topbar-items fadeInDown', {
                'topbar-items-visible': this.topbarMenuActive
            }];
        },
        cabinetName() {
            if (window.application === 'cabinet') {
                return 'Cabinet';
            } else if (window.application === 'backoffice') {
                return 'Admin';
            } else {
                throw 'Invalid application';
            }
        }
    }
}
</script>

<style>
.app-topbar__logo {
    font-size: 30px;
    color: #fff;
    line-height: 0.9;
}

.layout-wrapper .topbar-items > li.profile-item button {
    font-size: 28px;
    top: 0;
    display: flex;
    align-items: center;
    color: #fff;
    line-height: 0.8;
}
</style>
