import axios from 'axios'

const isProd = process.env.NODE_ENV === 'production'

const baseURL = isProd
    ? process.env.VUE_APP_API_URL_PROD
    : process.env.VUE_APP_API_URL

export default class CampsService {
    getAvailableCamps() {
        const AuthStr = 'Bearer ' + JSON.parse(localStorage.getItem('user')).token;

        return axios.get(baseURL + 'sport-courses/camps', {headers: {Authorization: AuthStr}})
            .then(response => response.data)
            .catch(error => error);
    }

    getCamp(campId) {
        const AuthStr = 'Bearer ' + JSON.parse(localStorage.getItem('user')).token;

        return axios.get(baseURL + `sport-courses/camps/${campId}`, {headers: {Authorization: AuthStr}})
            .then(response => response.data)
            .catch(error => error);
    }

    deleteCamp(campId) {
        const AuthStr = 'Bearer ' + JSON.parse(localStorage.getItem('user')).token;

        return axios.delete(baseURL + `sport-courses/camps/${campId}`, {headers: {Authorization: AuthStr}})
            .then(response => response.data);
    }

    getAuthorizesAgentsCamp(campId) {
        const AuthStr = 'Bearer ' + JSON.parse(localStorage.getItem('user')).token;

        return axios.get(baseURL + `sport-courses/camps/${campId}/authorizes-agents`, {headers: {Authorization: AuthStr}})
            .then(response => response.data);
    }

    attachAuthorizesAgentsCamp(campId, request) {
        const AuthStr = 'Bearer ' + JSON.parse(localStorage.getItem('user')).token;

        return axios.post(baseURL + `sport-courses/camps/${campId}/authorizes-agents`, request, {headers: {Authorization: AuthStr}})
            .then(response => response.data);
    }

    unattachAuthorizesAgentsCamp(campId, request) {
        const AuthStr = 'Bearer ' + JSON.parse(localStorage.getItem('user')).token;

        return axios.put(baseURL + `sport-courses/camps/${campId}/authorizes-agents/unattach`, request, {headers: {Authorization: AuthStr}})
            .then(response => response.data);
    }

    updateAuthorizesAgentInfo(campId, authorizedAgentId, request) {
        const AuthStr = 'Bearer ' + JSON.parse(localStorage.getItem('user')).token;

        return axios.put(
                baseURL + `sport-courses/camps/${campId}/authorizes-agents/${authorizedAgentId}`,
                request,
                {headers: {Authorization: AuthStr}})
            .then(response => response.data);
    }

    createCamp(createdCampRequest) {
        const AuthStr = 'Bearer ' + JSON.parse(localStorage.getItem('user')).token;

        return axios.post(baseURL + `sport-courses/camps`, createdCampRequest, {headers: {Authorization: AuthStr}})
            .then(response => response.data)
            .catch(error => error);
    }

    updateCampInfo(campId, updatedCampRequest) {
        const AuthStr = 'Bearer ' + JSON.parse(localStorage.getItem('user')).token;

        return axios.put(baseURL + `sport-courses/camps/${campId}`, updatedCampRequest, {headers: {Authorization: AuthStr}})
            .then(response => response.data)
            .catch(error => error);
    }

    updateCommissionRules(campId, campCommissionRules) {
        const AuthStr = 'Bearer ' + JSON.parse(localStorage.getItem('user')).token;

        return axios.put(baseURL + `sport-courses/camps/${campId}/commission-rules`, {
            headers: {Authorization: AuthStr},
            campCommissionRules
        })
            .then(response => response.data)
            .catch(error => error);
    }
}
