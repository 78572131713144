import {createRouter, createWebHistory} from 'vue-router';
import Cookies from 'vue-cookies';

const router = createRouter({
    history: createWebHistory(),
    routes: getRouters(),
});

function getRouters() {
    return [
        {
            path: '/:campId/registration-forms/:registrationId',
            name: 'registrationForm',
            component: () => import('./pages/RegistrationForm.vue')
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('./pages/Login.vue')
        },
        {
            path: '/activate',
            name: 'activate',
            component: () => import('./pages/ActivateAccount.vue')
        },
        {
            path: '/dates',
            name: 'dates',
            exact: true,
            meta: {
                requiresAuth: true
            },
            component: () => import('./components/SportPrograms/Dates/SportProgramDates')
        },
        {
            path: '/orders',
            name: 'orders',
            exact: true,
            meta: {
                requiresAuth: true
            },
            component: () => import('./components/Orders/Orders')
        },
        {
            path: '/orders/:orderId',
            name: 'order',
            exact: true,
            meta: {
                requiresAuth: true
            },
            component: () => import('./components/Orders/Order.vue')
        },
        {
            path: '/queries',
            name: 'queries',
            exact: true,
            meta: {
                requiresAuth: true
            },
            component: () => import('./components/Orders/Queries')
        },
        {
            path: '/',
            name: 'dashboard',
            exact: true,
            meta: {
                requiresAuth: true
            },
            component: () => import('./components/SportPrograms/SportPrograms.vue')
        },
        {
            path: '/sport-programs',
            name: 'sport-programs',
            exact: true,
            meta: {
                requiresAuth: true
            },
            component: () => import('./components/SportPrograms/SportPrograms.vue')
        },
        {
            path: '/sport-programs/:sportProgramId',
            name: 'sport-program',
            meta: {
                requiresAuth: true
            },
            component: () => import('./components/SportPrograms/SportProgram.vue')
        },
        {
            path: '/camps',
            name: 'camps',
            exact: true,
            meta: {
                requiresAuth: true
            },
            component: () => import('./components/Camps/Camps.vue')
        },
        {
            path: '/camps/new',
            name: 'add-camp',
            exact: true,
            meta: {
                requiresAuth: true
            },
            component: () => import('./components/Camps/AddCamp.vue')
        },
        {
            path: '/camps/:campId',
            name: 'camp',
            exact: true,
            meta: {
                requiresAuth: true
            },
            component: () => import('./components/Camps/Camp.vue')
        },
        {
            path: '/camps/:campId/new',
            name: 'new-sport-program',
            exact: true,
            meta: {
                requiresAuth: true
            },
            component: () => import('./components/SportPrograms/AddSportProgram.vue')
        },
        {
            path: '/settings',
            name: 'settings',
            exact: true,
            meta: {
                requiresAuth: true
            },
            component: () => import('./components/Settings/Settings')
        },
        {
            path: '/accounts',
            name: 'accounts',
            exact: true,
            meta: {
                requiresAuth: true
            },
            component: () => import('./components/Accounts/Accounts')
        }
    ];
}

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (Cookies.get('jwt') == null) {
            next({
                path: '/login'
            });
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;
