import axios from 'axios'

const isProd = process.env.NODE_ENV === 'production'

const baseURL = isProd
    ? process.env.VUE_APP_API_URL_PROD
    : process.env.VUE_APP_API_URL

export default class AccountsService {
    getAccounts() {
        return axios.get(baseURL + 'accounts', this.getConfig()).then(response => response.data);
    }

    delete(id) {
        return axios.delete(baseURL + 'accounts/'+id, this.getConfig()).then(response => response.data);
    }

    block(id) {
        return axios.put(baseURL + 'accounts/'+id+'/block', null, this.getConfig()).then(response => response.data);
    }

    resetActivating(id) {
        return axios.put(baseURL + 'accounts/'+id+'/reset-activating', null, this.getConfig()).then(response => response.data);
    }

    activate(activateRequest) {
        return axios.put(baseURL + `accounts/activate`, activateRequest).then(response => response.data);
    }

    createAccount(newAccountRequest) {
        console.log(newAccountRequest);

        return axios.post(baseURL + `accounts`, newAccountRequest, this.getConfig()).then(response => response.data);
    }

    getConfig(){
        const AuthStr = 'Bearer ' + JSON.parse(localStorage.getItem('user')).token;
        return {headers: {Authorization: AuthStr}};
    }
}
