<template>
    <div :class="containerClass" @click="onDocumentClick">
        <Toast/>
        <div class="layout-wrapper-content">
            <AppTopBar :topbarMenuActive="topbarMenuActive" :profileMode="profileMode"
                       :horizontal="layoutMode === 'horizontal'" :activeTopbarItem="activeTopbarItem"
                       @menubutton-click="onMenuButtonClick" @topbar-menubutton-click="onTopbarMenuButtonClick"
                       @topbar-item-click="onTopbarItemClick"></AppTopBar>

            <transition name="layout-menu-container">
                <div :class="menuClass" @click="onMenuClick" v-show="isMenuVisible()">
                    <div class="menu-scroll-content">
                        <AppMenu :model="menu" :layoutMode="layoutMode" :active="menuActive"
                                 @menuitem-click="onMenuItemClick" @root-menuitem-click="onRootMenuItemClick"></AppMenu>
                    </div>
                </div>
            </transition>

            <div class="layout-main">
                <router-view/>
            </div>
            <div class="layout-mask"></div>

            <AppFooter />
        </div>
    </div>
</template>

<script>
import AppTopBar from './AppTopbar.vue';
import AppMenu from './AppMenu.vue';
import AppFooter from './AppFooter.vue';

import EventBus from '@/event-bus';
import CampsService from "./service/CampsService";

export default {
    data() {
        return {
            camps: [],
            layoutMode: 'static',
            profileMode: 'top',
            overlayMenuActive: false,
            staticMenuDesktopInactive: false,
            staticMenuMobileActive: false,
            topbarMenuActive: false,
            activeTopbarItem: null,
            rotateMenuButton: false,
            darkMenu: false,
            menu: this.getMenu(),
            menuActive: false,
            profileExpanded: false,
            theme: 'blue',
            layout: 'blue'
        }
    },
    campsService: null,
    created() {
        this.campsService = new CampsService();
    },
    watch: {
        $route() {
            this.menuActive = false;
            this.$toast.removeAllGroups();
        }
    },
    methods: {
        getMenu() {
            let menu = [
                {label: 'Programs', icon: 'pi pi-fw pi-globe', to: '/sport-programs'},
            ];

            if (this.hasPermission("camps_crud")) {
                menu.push({label: 'Camps', icon: 'pi pi-fw pi-building', to: '/camps'});
            }

            menu.push({label: 'Orders', icon: 'pi pi-fw pi-dollar', to: '/orders'});

            if (this.hasRole("superadmin")) {
                menu.push({label: 'Queries', icon: 'pi pi-fw pi-envelope', to: '/queries'});
                menu.push({label: 'Settings', icon: 'pi pi-fw pi-sliders-v', to: '/settings'});
            }

            if (this.hasPermission("accounts_crud")) {
                menu.push({label: 'Accounts', icon: 'pi pi-fw pi-users', to: '/accounts'});
            }

            return menu;
        },

        onDocumentClick() {
            if (!this.topbarItemClick) {
                this.activeTopbarItem = null;
                this.topbarMenuActive = false;
            }

            if (!this.menuClick) {
                if (this.isHorizontal() || this.isSlim()) {
                    this.menuActive = false;
                    EventBus.emit('reset_active_index');
                }

                this.hideOverlayMenu();
            }

            this.topbarItemClick = false;
            this.menuClick = false;
        },
        onMenuClick() {
            this.menuClick = true;
        },
        onMenuItemClick(event) {
            if (!event.item.items) {
                EventBus.emit('reset_active_index');
                this.hideOverlayMenu();
            }
            if (!event.item.items && this.isHorizontal()) {
                this.menuActive = false;
            }
        },
        onRootMenuItemClick() {
            this.menuActive = !this.menuActive;
        },
        onMenuButtonClick(event) {
            this.menuClick = true;

            this.rotateMenuButton = !this.rotateMenuButton;
            this.topbarMenuActive = false;

            if (this.layoutMode === 'overlay') {
                this.overlayMenuActive = !this.overlayMenuActive;
            } else {
                if (this.isDesktop())
                    this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
                else
                    this.staticMenuMobileActive = !this.staticMenuMobileActive;
            }

            event.preventDefault();
        },
        onTopbarMenuButtonClick(event) {
            this.activeTopbarItem = 'profile';

            this.topbarItemClick = true;
            this.topbarMenuActive = !this.topbarMenuActive;
            this.hideOverlayMenu();
            event.preventDefault();
        },
        onTopbarItemClick(event) {
            this.topbarItemClick = true;

            if (this.activeTopbarItem === event.item)
                this.activeTopbarItem = null;
            else
                this.activeTopbarItem = event.item;

            event.originalEvent.preventDefault();
        },
        hideOverlayMenu() {
            this.rotateMenuButton = false;
            this.overlayMenuActive = false;
            this.staticMenuMobileActive = false;
        },
        isDesktop() {
            return window.innerWidth > 1024;
        },
        isHorizontal() {
            return this.layoutMode === 'horizontal';
        },
        isSlim() {
            return this.layoutMode === 'slim';
        },
        isMenuVisible() {
            if (this.isDesktop()) {
                if (this.layoutMode === 'static')
                    return !this.staticMenuDesktopInactive;
                else if (this.layoutMode === 'overlay')
                    return this.overlayMenuActive;
                else
                    return true;
            } else {
                if (this.staticMenuMobileActive)
                    return true;
                else if (this.layoutMode === 'overlay')
                    return this.overlayMenuActive;
                else
                    return true;
            }
        },
        replaceLink(linkElement, href) {
            const id = linkElement.getAttribute('id');
            const cloneLinkElement = linkElement.cloneNode(true);

            cloneLinkElement.setAttribute('href', href);
            cloneLinkElement.setAttribute('id', id + '-clone');

            linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

            cloneLinkElement.addEventListener('load', () => {
                linkElement.remove();
                cloneLinkElement.setAttribute('id', id);
            });
        }
    },
    computed: {
        containerClass() {
            return ['layout-wrapper', {
                'menu-layout-static': this.layoutMode !== 'overlay',
                'menu-layout-overlay': this.layoutMode === 'overlay',
                'layout-menu-overlay-active': this.overlayMenuActive,
                'menu-layout-slim': this.layoutMode === 'slim',
                'menu-layout-horizontal': this.layoutMode === 'horizontal',
                'layout-menu-static-inactive': this.staticMenuDesktopInactive,
                'layout-menu-static-active': this.staticMenuMobileActive,
                // 'p-input-filled': this.$appState.inputStyle === 'filled',
                // 'p-ripple-disabled': this.$primevue.ripple === false
            }];
        },
        menuClass() {
            return ['layout-menu-container', {'layout-menu-dark': this.darkMenu}];
        }
    },
    components: {
        'AppTopBar': AppTopBar,
        'AppMenu': AppMenu,
        'AppFooter': AppFooter
    }
}
</script>


<style>
.p-menuitem-separator {
    padding-left: 8px;
    padding-right: 8px;
    color: #6c757d;
}
</style>